import React from 'react';

import { Container } from '@myungsoo/components';

const Contact = ({
  config: { DB_ROOT_PATH },
  onLoadingChange = () => {},
  ...restProps
}) => {
  const [page, setPage] = React.useState({ content: '' });

  const loadPage = React.useCallback(async () => {
    onLoadingChange(true);
    try {
      const firebase = await import('firebase/app');
      const db = firebase.firestore();
      const pageDoc = await db.doc(`${DB_ROOT_PATH}/pages/contact`).get();

      if (!pageDoc.exists) {
        return;
      }

      setPage(pageDoc.data());
    } finally {
      onLoadingChange(false);
    }
  }, [DB_ROOT_PATH, onLoadingChange]);

  React.useEffect(() => {
    loadPage();
  }, [loadPage]);

  return (
    <Container {...restProps}>
      <div dangerouslySetInnerHTML={{ __html: page.content }} />
    </Container>
  );
};

export default Contact;
