import React from 'react';

import Contact from '@myungsoo/base/pages/Contact';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

import * as config from '../../config';

const ContactPage = () => {
  const [loading, setLoading] = React.useState(false);

  return (
    <Layout loading={loading}>
      <Seo title="Contact" />
      <Contact config={config} onLoadingChange={setLoading} />
    </Layout>
  );
};

export default ContactPage;
